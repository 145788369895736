import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import GenericButton from '@/components/Common/GenericButton';
import ImportCandidatesCSVModal from '../../SearchView/CandidatesListPane/ImportCandidates/ImportCandidatesCSVModal';

import './ImportCandidatesDropdown.css';

const ImportCandidatesDropdown = ({ clientId, jobId, onSuccess }) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);

  return (
    <>
      <GenericButton
        primacy='secondary'
        className='import-candidates hs-default-dropdown'
        onClick={openModal}
      >
        {t('importeCandidateDropdown.importContacts')}
      </GenericButton>
      {isOpen && (
        <ImportCandidatesCSVModal
          clientId={clientId}
          jobId={jobId}
          onSuccess={onSuccess}
          onClose={closeModal}
          open
        />
      )}
    </>
  );
};

export default ImportCandidatesDropdown;
