import React from 'react';
import { useTranslation } from 'react-i18next';
import { Copyable } from '@hiresweet/hiresweet-lib';

import SettingsLayout from '@/containers/SettingsLayout';
import useClientId from '@/hooks/router/useClientId';

import useNootaGlobalToken from './useNootaGlobalToken';

function NootaIntegration() {
  const { t } = useTranslation();
  const clientId = useClientId();
  const { token } = useNootaGlobalToken();
  return (
    <SettingsLayout
      currentPage='noota'
      clientId={clientId}
      className='settings-container'
    >
      <div className='settings'>
        <div className='settings-header'>
          <h1>{t('settings.noota.title')}</h1>
        </div>
        <h2>{t('settings.noota.token')}</h2>
        {token && <Copyable>{token}</Copyable>}
      </div>
    </SettingsLayout>
  );
}

export default NootaIntegration;
