import _ from 'underscore';

import useExternalIntegrationTokens from './useExternalIntegrationTokens';

function useNootaGlobalToken() {
  const query = useExternalIntegrationTokens({
    integrationId: 'noota',
  });
  const nootaGlobalToken = _.findWhere(query.externalIntegrationTokens, {
    type: 'global',
  });
  const token = nootaGlobalToken?.token;
  return { ...query, token };
}

export default useNootaGlobalToken;
