import gql from 'graphql-tag';
import { useQuery, type QueryHookOptions } from '@apollo/client';

import useClientId from '@/hooks/router/useClientId';

const getExternalIntegrationTokens = gql`
  query getExternalIntegrationTokens($clientId: ID!, $integrationId: String!) {
    client(id: $clientId) {
      id
      externalIntegrationTokens(integrationId: $integrationId) {
        id
        type
        token
      }
    }
  }
`;

type Data = {
  client?: {
    id: string;
    externalIntegrationTokens: {
      id: string;
      type: string;
      token: string;
    }[];
  };
};

type Variables = {
  clientId: string;
  integrationId: string;
};

type Input = {
  integrationId: string;
  queryOptions?: QueryHookOptions<Data, Variables>;
};

function useExternalIntegrationTokens({
  integrationId,
  queryOptions = {},
}: Input) {
  const clientId = useClientId();
  const query = useQuery<Data, Variables>(getExternalIntegrationTokens, {
    ...queryOptions,
    variables: {
      clientId,
      integrationId,
    },
  });
  const externalIntegrationTokens =
    query.data?.client?.externalIntegrationTokens ?? [];
  return { ...query, externalIntegrationTokens };
}

export default useExternalIntegrationTokens;
